import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./src/styles/global.css";
const Bugsnag = require("@bugsnag/js").default;
const BugsnagPluginReact = require("@bugsnag/plugin-react").default;

Bugsnag.start({
  apiKey: process.env.GATSBY_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
});
